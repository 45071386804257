import { likePost } from "./_post-reactions";
import { intersectionObserve } from "./_intersection-observer";

const wallPage = document.querySelector('body.page-template-page-sharing-wall');

if(wallPage) {
    const form = wallPage.querySelector('.wall-form');
    const container = wallPage.querySelector('.wall-posts');
    const textarea = form.querySelector('textarea');
    const fileInput = form.querySelector('input[type="file"]');
    const uploadLabel = form.querySelector('label.image-upload');
    const submitButton = form.querySelector('button.submit-btn');
    const removeButton = form.querySelector('button.remove-post');
    const loadingSpinner = form.querySelector('.loading-spinner');

    let postOffset = 10;
    let totalPosts = parseInt(container.getAttribute('data-total'));
    let selectedFiles = [];
    let isUploading = false;

    const FILE_LIMITS = {
        maxFiles: 4,
        maxFileSize: 25 * 1024 * 1024, // 5MB in bytes
        allowedTypes: ['image/jpeg', 'image/png', 'image/gif'],
        totalMaxSize: 100 * 1024 * 1024 // 20MB total
    };
    
    // Error message container
    const errorContainer = document.createElement('div');
    errorContainer.className = 'upload-errors';
    const imagesWrapper = form.querySelector('.images-wrapper');
    form.insertBefore(errorContainer, imagesWrapper);



    //Create, show and hide error message
    function showError(message, duration = 5000) {
        errorContainer.innerHTML = `<p class="error">${message}</p>`;
        errorContainer.style.display = 'block';
        setTimeout(() => {
            errorContainer.style.display = 'none';
            errorContainer.innerHTML = '';
        }, duration);
    }
    //this needs an adult to be honest...ʕ -㉨- ʔ
    function formatFileSize(bytes) {
        if (bytes < 1024) return bytes + ' B';
        else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
        else return (bytes / 1048576).toFixed(1) + ' MB';
    }

    function validateFiles(files) {
        const errors = [];
        let totalSize = 0;

        selectedFiles.forEach(file => {
            totalSize += file.size;
        });

        // Files larger than total amount
        if (selectedFiles.length + files.length > FILE_LIMITS.maxFiles) {
            errors.push(`Maksimum ${FILE_LIMITS.maxFiles} bilder tillatt`);
            return errors;
        }

        // Check each new file
        for (let file of files) {
            // Check file type
            if (!FILE_LIMITS.allowedTypes.includes(file.type)) {
                errors.push(`${file.name}: Ugyldig filtype. Bruk JPG, PNG eller GIF`);
                continue;
            }

            // Check individual file size
            if (file.size > FILE_LIMITS.maxFileSize) {
                const maxSize = formatFileSize(FILE_LIMITS.maxFileSize);
                const fileSize = formatFileSize(file.size);
                errors.push(`${file.name}: Filen er for stor (${fileSize}). Maksimal størrelse er ${maxSize}`);
                return errors;
            }

            totalSize += file.size;
        }

        // Check total size, might need some looking into... ʕ -㉨- ʔ
        if (totalSize > FILE_LIMITS.totalMaxSize) {
            const maxTotal = formatFileSize(FILE_LIMITS.totalMaxSize);
            const currentTotal = formatFileSize(totalSize);
            errors.push(`Total størrelse (${currentTotal}) kan ikke overstige ${maxTotal}`);
            return errors;
        }

        return errors;
    }

    function updateUploadButton() {
        if (selectedFiles.length >= FILE_LIMITS.maxFiles) {
            uploadLabel.classList.add('hide', 'disabled');
        } else {
            uploadLabel.classList.remove('hide', 'disabled');
        }
    }

    function resetForm() {
        textarea.value = '';
        form.classList.remove('expand');
        uploadLabel.classList.remove('hide', 'disabled');
        selectedFiles = [];

        const imagesWrapper = form.querySelector('.images-wrapper');
        if (imagesWrapper) {
            imagesWrapper.innerHTML = '';
        }

        updateUploadButton();
    }

    // Form event handlers
    textarea.addEventListener('keyup', (e) => {
        const value = e.target.value.trim();
        if(value !== '' && !form.classList.contains('expand')) {
            form.classList.add('expand');
        }
        else if(value === '' && !form.querySelectorAll('.images-wrapper figure').length) {
            form.classList.remove('expand');
        }
    });

    fileInput.addEventListener('change', async (e) => {
        if (isUploading) {
            showError('Vennligst vent til nåværende opplasting er ferdig');
            fileInput.value = '';
            return;
        }

        const newFiles = Array.from(e.target.files);
        const errors = validateFiles(newFiles);

        if (errors.length > 0) {
            showError(errors.join('<br>'));
            fileInput.value = '';
            return;
        }

        try {
            selectedFiles = [...selectedFiles, ...newFiles];

            if(selectedFiles.length) {
                form.classList.add('expand');
                await insertImage(selectedFiles);
            }
        } catch (error) {
            console.error('File handling error:', error);
            showError('Feil ved håndtering av filer');
            selectedFiles = selectedFiles.filter(file => !newFiles.includes(file)); // Remove failed files
        }

        fileInput.value = '';
        updateUploadButton();
    });

    async function insertImage(files) {
        if (isUploading) return;

        isUploading = true;
        submitButton.disabled = true;
        updateUploadButton();

        try {
            const data = new FormData();
            data.append('action', 'insert_image');

            files.forEach((file, index) => {
                data.append(`file-${index}`, file);
            });

            loadingSpinner?.classList.add('show');

            const response = await $.ajax({
                url: feltpost_scripts.ajax_url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false
            });

            const imagesWrapper = form.querySelector('.images-wrapper');
            if (!imagesWrapper) {
                throw new Error('Images wrapper not found');
            }

            imagesWrapper.innerHTML = response;

            imagesWrapper.querySelectorAll('figure').forEach((figure, index) => {
                const removeBtn = figure.querySelector('.remove-image');
                if (removeBtn) {
                    removeBtn.addEventListener('click', (e) => {
                        e.preventDefault();
                        selectedFiles = selectedFiles.filter((_, i) => i !== index);
                        insertImage(selectedFiles);
                        if (!selectedFiles.length) {
                            form.classList.remove('expand');
                        }

                    });
                }
            });

        } catch (error) {
            console.error('Upload error:', error);
            showError('Feil ved opplasting av bilder. Vennligst prøv igjen.');
        } finally {
            loadingSpinner?.classList.remove('show');
            submitButton.disabled = false;
            isUploading = false;
            updateUploadButton();
        }
    }

    async function addPost(content) {
        if (!content && !selectedFiles.length) {
            showError('Legg til tekst eller bilder før du publiserer');
            return;
        }

        submitButton.disabled = true;

        try {
            const images = [];
            const imageElements = form.querySelectorAll('.images-wrapper figure img');

            imageElements.forEach(img => {
                if (img?.dataset.id) {
                    images.push(img.dataset.id);
                }
            });

            const data = new FormData();
            data.append('action', 'add_wall_post');
            data.append('content', content);
            data.append('images', JSON.stringify(images));

            const response = await $.ajax({
                url: feltpost_scripts.ajax_url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false
            });

            container.innerHTML = response + container.innerHTML;

            container.querySelectorAll('article').forEach(post => {
                const likeBtn = post.querySelector('button.like');
                if (likeBtn) {
                    likeBtn.addEventListener('click', () => likePost($(likeBtn)));
                }
            });

            postOffset++;
            totalPosts++;
            container.setAttribute('data-total', totalPosts);

            resetForm();

        } catch (error) {
            console.error('Post error:', error);
            showError('Feil ved publisering. Vennligst prøv igjen.');
        } finally {
            submitButton.disabled = false;
        }
    }

    async function loadMorePosts() {
        const loadingElement = wallPage.querySelector('.loading');

        try {
            const data = new FormData();
            data.append('action', 'get_wall_posts');
            data.append('offset', postOffset);

            loadingElement?.classList.add('show');

            const response = await $.ajax({
                url: feltpost_scripts.ajax_url,
                type: 'POST',
                data: data,
                cache: false,
                processData: false,
                contentType: false
            });

            container.insertAdjacentHTML('beforeend', response);
            postOffset += 10;

            container.querySelectorAll('article').forEach(post => {
                const likeBtn = post.querySelector('button.like');
                if (likeBtn) {
                    likeBtn.addEventListener('click', () => likePost($(likeBtn)));
                }
            });

        } catch (error) {
            console.error('Error loading posts:', error);
            showError('Feil ved lasting av flere innlegg. Vennligst prøv igjen.');
        } finally {
            loadingElement?.classList.remove('show');
        }
    }

    submitButton.addEventListener('click', async (e) => {
        e.preventDefault();
        await addPost(textarea.value.trim());
    });

    removeButton.addEventListener('click', (e) => {
        e.preventDefault();
        resetForm();
    });

    // Infinite scroll
    const loadingElement = wallPage.querySelector('.loading');
    if (loadingElement) {
        intersectionObserve(loadingElement, 0, 0, (element, intersectionRatio) => {
            if (intersectionRatio > 0 && postOffset < totalPosts && !isUploading) {
                loadMorePosts();
            }
        });
    }
}