const $removeButton = $('.wall-post-single #delete-post');
$removeButton.click(function(e) {
    e.preventDefault();

    const confirmed = confirm('Er du sikker på at du vil slette dette innlegget?');
    if (!confirmed) {
        return;
    }

    const postId = $(this).data('postId');

    let data = new FormData();
    data.append('action', 'delete_post');
    data.append('post_id', postId);

    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const response = data.responseJSON;

            if (response.status === 'ok') {
                location.reload();
            }
        }
    });

})